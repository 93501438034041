import styles from './index.module.css';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import AppToaster from 'helpers/toaster';
import { Button, EditableText, Intent, Tooltip } from '@blueprintjs/core';
import { SweepPayloadGroup, SweepPayloadParameter } from './types';
import { flatten } from 'lodash';
import { SweepParameterApplicationMethod } from 'graphql/generated/graphql';

type SweepPayloadTableProps = {
  sweepPayload?: SweepPayloadGroup[];
  setSweepPayload: (input: SweepPayloadGroup[]) => void;
}

const SweepPayloadTable = (props : SweepPayloadTableProps) => {
  const { sweepPayload, setSweepPayload } = props;
  const [selectedRuns, setSelectedRuns] = useState<number[]>([]);
  const [groups, setGroups] = useState<SweepPayloadGroup[]>([]);
  const [tableLength, setTableLength] = useState<number>(0);

  useEffect(() => {
    if (!sweepPayload) return;
    try {
      setTableLength(sweepPayload.reduce((acc, sp) => acc + sp.sweepDataLength, 0));
      setGroups(sweepPayload);
    } catch (e) {
      AppToaster.show({
        intent: Intent.DANGER,
        message: 'Error importing payload',
      });
    }
  }, [sweepPayload]);

  const onCellValueChange = useCallback((parameter: SweepPayloadParameter, value: string, rowIndex: number) => {
    if (sweepPayload) {
      const newSweepPayload = sweepPayload;
      let valueIndex = rowIndex;
      const newGroups = sweepPayload.map((sweepGroup, groupIndex) => {
        const newGroup = {
          parameters: sweepGroup.parameters.map((p, parameterIndex) => {
            if (p.path === parameter.path) {
              parameter.values[valueIndex] = value;
              newSweepPayload[groupIndex].parameters[parameterIndex].values = parameter.values;
              return parameter;
            }
            newSweepPayload[groupIndex].parameters[parameterIndex].values = p.values;
            return p;
          }),
          sweepDataLength: sweepGroup.sweepDataLength,
        };

        valueIndex -= sweepGroup.sweepDataLength;
        return newGroup;
      });
      setGroups(newGroups);
      setSweepPayload(newSweepPayload);
    }
  }, [sweepPayload]);

  const removeDataTableRowData = (payload: SweepPayloadGroup[], rowIndex: number, tableLength: number) => {
    const updatedSweepPayload = [...payload];
    let grpOffsetIndex = 0;
    let foundGroup = false;

    // Loop through Sweep param groups to update correct group.  Each Group produces 1~many rows.
    updatedSweepPayload.forEach((group) => {
      if (!foundGroup && ((rowIndex + 1) <= (grpOffsetIndex + group.sweepDataLength))) {
        group.parameters.forEach(param => {
          param.values.splice((rowIndex - grpOffsetIndex), 1);
        });
        group.sweepDataLength -= 1;
        foundGroup = true;
      }
      grpOffsetIndex += group.sweepDataLength;
    });

    setTableLength(tableLength - 1);

    return updatedSweepPayload;
  };

  const handleDeleteSelectedRuns = (tableLength: number, groups: SweepPayloadGroup[], payload: SweepPayloadGroup[] | undefined) => {
    if (selectedRuns.length > 0 && payload?.length && payload.length > 0) {
      selectedRuns.sort((a, b) => b - a); // Sort max first
      let updatedSweepPayload = [...payload];
      for (let i = 0; i < selectedRuns.length; i++) {
        updatedSweepPayload = removeDataTableRowData(updatedSweepPayload, selectedRuns[i], tableLength);
      }

      setSweepPayload(updatedSweepPayload);
      setSelectedRuns([]);
    }
  };

  const handleRunSelect = useCallback((rowIndex: number) => {
    if (selectedRuns.includes(rowIndex)) {
      setSelectedRuns(selectedRuns.filter(index => index !== rowIndex));
    } else {
      setSelectedRuns([...selectedRuns, rowIndex]);
    }
  }, [selectedRuns]);

  const renderSweepDataTable = (
    tableLength: number,
    groups: SweepPayloadGroup[],
    payload: SweepPayloadGroup[],
  ): ReactNode => {
    let currentGroupIndex = 0;
    let groupDataIndex = 0;
    return [...Array(tableLength)].map((item, rowIndex) => {
      const currentGroup = payload[currentGroupIndex];
      const tableRow = (
        <tr
          className={`${styles.textCenterRow} ${selectedRuns.includes(rowIndex) ? styles.highlightedRow : ''}`}
        >
          <td className={styles.runColumnStyle} onClick={() => handleRunSelect(rowIndex)}>{rowIndex + 1}</td>
          {flatten(groups.map(g => g.parameters)).map(parameter => {
            if (currentGroup?.parameters.find(p => p.path === parameter.path)) {
              return (
                <td>
                  {(parameter.method === SweepParameterApplicationMethod.DELTA) && (<>Δ</>)}
                  <EditableText
                    value={parameter.values[groupDataIndex]?.toString()}
                    onChange={value => {
                      onCellValueChange(parameter, value, rowIndex);
                    }}
                    selectAllOnFocus
                  />
                </td>
              );
            }
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            return (<td />);
          })}
        </tr>
      );

      if ((groupDataIndex + 1 >= currentGroup?.sweepDataLength) && (currentGroupIndex < payload.length)) {
        currentGroupIndex++;
        groupDataIndex = 0;
      } else {
        groupDataIndex++;
      }
      return tableRow;
    });
  };

  return (
    <div>
      <Tooltip className={styles.deleteRuns} content="Delete selected/highlighted payload runs.">
        <Button
          intent={Intent.DANGER}
          type="button"
          icon="cross"
          text="Delete Run(s)"
          minimal
          onClick={() => handleDeleteSelectedRuns(tableLength, groups, sweepPayload)}
        />
      </Tooltip>
      <table className={styles.payloadTable}>
        {groups.length > 0 && sweepPayload && (
          <>
            <tr className={styles.textCenterColumnHeader}>
              <th className={styles.runColumnTHStyle}>Run</th>
              {flatten(groups.map(g => g.parameters)).map((parameter) => (
                <th>{parameter.path}</th>
              ))}
            </tr>
            {renderSweepDataTable(tableLength, groups, sweepPayload)}
          </>
        )}
      </table>
    </div>
  );
};

export default SweepPayloadTable;
