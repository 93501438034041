import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Button, Dialog, DialogBody, DialogFooter, Intent } from '@blueprintjs/core';
import classNames from 'classnames';
import _, { find, get } from 'lodash';

import SetupFieldForm from 'components/SetupFieldForm';
import {
  CreateSetupFieldInput,
  SetupField,
  SetupFieldPosition,
  SetupFieldType,
  SetupFieldsDocument,
  Spec,
  UpdateSetupFieldInput,
  useCreateSetupFieldMutation,
  useUpdateSetupFieldMutation,
  useSetupFieldsByTypeQuery,
} from 'graphql/generated/graphql';
import AppToaster from 'helpers/toaster';
import { selectDarkMode } from 'reducers/ui';
import { SetupFieldFormInput, SetupFieldInput } from 'types';
import { useRouteLoaderData } from 'react-router-dom';
import { validateExpressionSyntax, validateFieldReferences } from 'helpers/expressionsValidation';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (setupField: SetupField) => void;
  setupField?: SetupFieldFormInput | null;
}

const defaultSetupField: SetupFieldInput = {
  type: SetupFieldType.STRING,
  specs: [] as Spec[],
  name: '',
  label: '',
  path: '',
  tooltip: '',
  positions: [] as SetupFieldPosition[],
  options: [] as string[],
  use_in_sim: false,
  setup_field_array: [] as SetupFieldInput[],
  organization_id: '',
  organization_name: '',
  team_id: null,
  team_name: null,
  series: '',
  child_ids: [],
};

export default (props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { teams, organizations } } = useRouteLoaderData('root') as any;

  let defaultValues = defaultSetupField;
  if (props.setupField) {
    defaultValues = {
      ..._.omit(props.setupField, 'part_config'),
      part_config_id: props.setupField.part_config?.id,
      setup_field_array: props.setupField.setup_field_array
        ? props.setupField.setup_field_array.map(item => {
          const newItem = _.omit(item, 'part_config');
          newItem.child_ids = [];
          return newItem;
        })
        : [],
    };
  }

  const darkMode = useSelector(selectDarkMode);
  const form = useForm<SetupFieldInput>({ defaultValues });

  useEffect(() => {
    form.reset(defaultValues);
  }, [props.setupField]);

  const isEdit = Boolean(props.setupField?.id);

  const [createSetupField] = useCreateSetupFieldMutation();
  const [updateSetupField] = useUpdateSetupFieldMutation();

  const currentType = useWatch({ control: form.control, name: 'type' });

  // Skip the query unless type is EXPRESSION
  const { data: allSetupFieldsData } = useSetupFieldsByTypeQuery({
    skip: currentType !== SetupFieldType.EXPRESSION,
    variables: {
      input: {
        filters: {
          type: [SetupFieldType.FLOAT],
        },
      },
    },
  });

  const setupFields = useMemo(() => {
    return (allSetupFieldsData?.setupFields.rows || []) as SetupField[];
  }, [allSetupFieldsData]);

  // Convert expression references from ids to names
  useEffect(() => {
    if (props.setupField?.type === SetupFieldType.EXPRESSION && props.setupField.expression) {
      const expressionWithNames = props.setupField.expression.replace(/\{(\d+)\}/g, (match, id) => {
        const field = setupFields.find(f => f.id === parseInt(id, 10));
        return field ? `{${field.name}}` : match;
      });
      form.setValue('expression', expressionWithNames);
    }
  }, [props.setupField, setupFields]);

  const validateExpression = (input: SetupFieldInput, setupFields: SetupField[]): void => {
    const { expression } = input;

    if (!expression) {
      throw new Error('Expression is required');
    }

    // Convert expression with IDs to names for validation
    const expressionWithNames = expression.replace(/\{(\d+)\}/g, (match, id) => {
      const field = setupFields.find(f => f.id === parseInt(id, 10));
      return field ? `{${field.name}}` : match;
    });

    validateExpressionSyntax(expressionWithNames);
    validateFieldReferences({
      fields: setupFields,
      expression: expressionWithNames,
      fieldType: 'setup',
    });
  };

  const handleCreateSetupField = (input: CreateSetupFieldInput) => {
    const findOrg = find(organizations, org => input.organization_name === org.name);
    const findTeam = find(teams, team => input.team_name === team.name);

    createSetupField({
      variables: {
        input: {
          ...input,
          organization_id: get(findOrg, 'id', null),
          team_id: get(findTeam, 'id', null),
        },
      },
      onCompleted: setupField => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Setup field successfully created',
        });
        form.reset(defaultSetupField);
        props.onSuccess(setupField.setupField as SetupField);
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error creating setup field: ${e.message}`,
        });
      },
      update: (cache, { data: mutationData }) => {
        if (mutationData?.setupField) {
          cache.updateQuery({
            query: SetupFieldsDocument,
          }, queryData => {
            if (!queryData) return undefined;
            return {
              setupFields: {
                rows: [
                  ...queryData.setupFields.rows,
                  mutationData?.setupField,
                ],
              },
            };
          });
        }
      },
    });
  };
  const handleUpdateSetupField = (input: UpdateSetupFieldInput) => {
    updateSetupField({
      variables: { input },
      onCompleted: setupField => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Setup field successfully updated',
        });
        form.reset(defaultSetupField);
        props.onSuccess(setupField.setupField as SetupField);
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error updating setup field: ${e.message}`,
        });
      },
    });
  };

  const onClose = () => {
    form.reset(defaultSetupField);
    props.onClose();
  };

  const onSubmit = (input: SetupFieldInput) => {
    if (input.type === SetupFieldType.EXPRESSION) {
      try {
        if (!input.expression) {
          throw new Error('Expression is required for EXPRESSION type fields');
        }

        validateExpression(input, setupFields);

        const expressionWithIds = input.expression.replace(/\{([^{}]+)\}/g, (match, name) => {
          const field = setupFields.find(f => f.name === name);
          return field ? `{${field.id}}` : match;
        });

        input.expression = expressionWithIds;
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred';
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Invalid expression: ${errorMessage}`,
        });
        return;
      }
    }

    if (isEdit) handleUpdateSetupField(input as UpdateSetupFieldInput);
    else handleCreateSetupField(input as CreateSetupFieldInput);
  };

  return (
    <Dialog
      className={classNames({ 'bp4-dark': darkMode })}
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={`${isEdit ? 'Edit' : 'Create'} Setup Field`}
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogBody>
            <SetupFieldForm
              isEdit={isEdit}
              setupFields={setupFields}
            />
          </DialogBody>
        </form>
      </FormProvider>
      <DialogFooter
        actions={(
          <>
            <Button
              onClick={onClose}
              text="Cancel"
            />
            <Button
              intent={Intent.PRIMARY}
              onClick={form.handleSubmit(onSubmit)}
              key="submit"
              text="Save"
            />
          </>
        )}
      />
    </Dialog>
  );
};
