import { KeyboardEvent, useState } from 'react';
import { useNavigate, useParams, useRouteLoaderData } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, H3, Intent } from '@blueprintjs/core';
import { find, get, omit } from 'lodash';

import PartConfigForm from 'components/PartConfigForm';
import {
  UpdatePartConfigInput,
  usePartConfigByIdQuery,
  useUpdatePartConfigMutation,
} from 'graphql/generated/graphql';
import AppToaster from 'helpers/toaster';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import styles from './index.module.css';

const defaultPartConfig = {
  name: '',
  display_name: '',
  expires: false,
  properties: [],
};

export default () => {
  const navigate = useNavigate();
  const params = useParams();
  const configId = Number(params.configId);
  const [partConfigDisplayName, setPartConfigDisplayName] = useState('');

  useDocumentTitle(
    partConfigDisplayName ? `Apex Setup - ${partConfigDisplayName}` : 'Apex Setup'
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { teams, organizations } } = useRouteLoaderData('root') as any;

  const form = useForm<UpdatePartConfigInput>({ defaultValues: defaultPartConfig });

  const { refetch } = usePartConfigByIdQuery({
    variables: { id: configId },
    skip: !configId,
    onCompleted: data => {
      if (data?.partConfig) {
        form.reset({
          ...omit(data.partConfig, ['category']),
          part_category_id: data.partConfig.category.id,
        });
      } else {
        navigate('/parts/configs');
      }
      setPartConfigDisplayName(data?.partConfig?.display_name || '');
    },
  });
  const [updatePartConfig, { loading: isSaving }] = useUpdatePartConfigMutation();

  const onSubmit = (input: UpdatePartConfigInput) => {
    const findOrg = find(organizations, org => input.organization_name === org.name);
    const findTeam = find(teams, team => input.team_name === team.name);
    updatePartConfig({
      variables: {
        input: {
          ...input,
          properties: input.properties?.map(p => omit(p, 'property')) ?? [],
          organization_id: get(findOrg, 'id', ''),
          team_id: get(findTeam, 'id', null),
        },
      },
      onCompleted: async () => {
        // After successful mutation, refetch the data
        const { data } = await refetch();

        // Reset form with new data if available
        if (data?.partConfig) {
          form.reset({
            ...omit(data.partConfig, ['category']),
            part_category_id: data.partConfig.category.id,
          });
        }

        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Part config successfully updated',
        });
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error updating part config: ${e.message}`,
        });
      },
    });
  };

  // Prevents submission on Enter:
  // Tag inputs use Enter to "tagify" input which is triggering form submission
  const checkKeyDown = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') e.preventDefault();
  };

  return (
    <>
      <H3>Edit Part Config</H3>
      <FormProvider {...form}>
        <form
          className={styles.form}
          onKeyDown={checkKeyDown}
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <PartConfigForm />
          <Button
            className={styles.saveButton}
            icon="floppy-disk"
            intent={Intent.PRIMARY}
            large
            text="Save"
            type="submit"
            disabled={isSaving}
          />
        </form>
      </FormProvider>
    </>
  );
};
