import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Dialog, DialogBody, DialogFooter, Intent, Overlay, Spinner } from '@blueprintjs/core';
import classNames from 'classnames';
import { ColumnDef, RowSelectionState } from '@tanstack/react-table';
import { isNil, debounce, mapValues, keyBy, isEmpty, each, keys, omit, find } from 'lodash';

import Table, { ParamsChangeFn } from 'components/Table';
import {
  useSetupSummaryLazyQuery,
  SetupsQueryInput,
} from 'graphql/generated/graphql';
import { selectDarkMode } from 'reducers/ui';
import { selectSetupSummaryView, tableViewSlice } from 'reducers/tableView';
import { FilterType, GQLSetup, GQLSetupHead, SetupBranchChild, SetupSelection } from 'types';
import { format } from 'date-fns';
import { seriesItems } from '../../constants';
import styles from './index.module.css';

type SetupTableRow = GQLSetup & {
  branch: {
    id: number,
    name: string,
  },
  numBranches?: number | null;
  subRows?: SetupTableRow[];
  childBranches?: Array<{
    setup: GQLSetup;
    branch: {
      id: number;
      name: string;
    };
  }>;
}

const convertSetupData = (setup: GQLSetup, branch: { id: number; name: string }, numBranches?: number | null): SetupTableRow => {
  return {
    ...setup,
    branch: {
      id: branch.id,
      name: branch.name,
    },
    numBranches,
  };
};

const GQLBranchHeadToSetupTableRow = (item: GQLSetupHead): SetupTableRow => {
  const { numBranches, setup, branch } = item;
  return convertSetupData(setup, branch, numBranches);
};

const GQLBranchChildToSetupTableRow = (child: SetupBranchChild): SetupTableRow => {
  const { setup, branch } = child;
  return convertSetupData(setup, branch);
};

const columns = [
  {
    header: 'Name',
    accessorKey: 'name',
    enableColumnFilter: true,
  },
  {
    header: 'Branch',
    accessorKey: 'branch.name',
    id: 'branch',
    enableColumnFilter: true,
    size: 125,
  },
  {
    header: 'Organization',
    accessorKey: 'organization_name',
    enableColumnFilter: true,
  },
  {
    header: 'Team',
    accessorKey: 'team_name',
    enableColumnFilter: true,
    size: 100,
  },
  {
    header: 'Series',
    accessorKey: 'series',
    accessorFn: (row) => {
      const getSeries = find(seriesItems, series => series.value === row.series);
      if (!getSeries) return '';
      return getSeries.label;
    },
    meta: {
      filter: {
        type: FilterType.SELECT,
        selectItems: seriesItems,
        multiSelect: true,
      },
    },
    enableColumnFilter: true,
    size: 100,
  },
  {
    header: 'Year',
    accessorKey: 'year',
    enableColumnFilter: true,
    size: 100,
  },
  {
    header: 'Event',
    accessorKey: 'event',
    enableColumnFilter: true,
  },
  {
    header: 'Description',
    accessorKey: 'description',
    enableColumnFilter: true,
    enableSorting: false,
  },
  {
    header: 'Owner',
    accessorKey: 'owner',
    enableColumnFilter: true,
    size: 125,
  },
  {
    header: 'Created',
    accessorFn: (row) => format(new Date(row.created_at), 'MM/dd/yy HH:mm:ss'),
  },
  {
    header: 'Modified',
    accessorFn: (row) => format(new Date(row.updated_at), 'MM/dd/yy HH:mm:ss'),
  },
] as ColumnDef<SetupTableRow>[];

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (setups: SetupSelection[]) => void;
  highlightedRows?: number[];
  singleSelection?: boolean
}

export default (props: Props) => {
  const dispatch = useDispatch();
  const darkMode = useSelector(selectDarkMode);
  const { tableFilters, tableSorting } = useSelector(selectSetupSummaryView);
  const [tableData, setTableData] = useState<SetupTableRow[]>([]);
  const [isAddDisabled, setIsAddDisabled] = useState(true);
  const [selectedRows, setSelectedRows] = useState({});
  const [highlightedRowIds, setHighlightedRowIds] = useState<number[]>([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const [getSetups, { data: setupsData, loading: isLoading }] = useSetupSummaryLazyQuery();

  // Update control state field on load,
  useEffect(() => {
    setHighlightedRowIds(props.highlightedRows || []);
  }, [props.highlightedRows]);

  useEffect(() => {
    if (!props.isOpen) {
      setIsInitialLoad(true);
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (!setupsData?.setupBranchHeads.rows) return;
    setIsInitialLoad(false);
    const newData = setupsData?.setupBranchHeads.rows as GQLSetupHead[];
    const translatedNewData: SetupTableRow[] = newData.map(data => {
      const row = GQLBranchHeadToSetupTableRow(data);
      if (data.childBranches?.length) {
        row.subRows = data.childBranches.map((child: SetupBranchChild) => GQLBranchChildToSetupTableRow(child));
      }
      return row;
    });

    setTableData(translatedNewData);
  }, [setupsData]);

  useEffect(() => {
    if (isEmpty(selectedRows)) {
      setIsAddDisabled(true);
    } else {
      setIsAddDisabled(false);
    }
  }, [selectedRows]);

  const onSelectionChange = (selections: RowSelectionState) => {
    setSelectedRows(selections);
  };

  const onSubmit = () => {
    const selectedSetups: SetupSelection[] = [];
    each(keys(selectedRows), (index: string) => {
      if (index.includes('.')) {
        const indices = index.split('.');
        const selectedSetup = tableData[Number(indices[0])].subRows?.[Number(indices[1])];
        if (selectedSetup) {
          selectedSetups.push({
            branch: selectedSetup.branch,
            setup: omit(selectedSetup, ['numBranches', 'subRows', 'branch']),
          });
        }
      } else {
        const selectedSetup = tableData[Number(index)];
        selectedSetups.push({
          branch: selectedSetup.branch,
          setup: omit(selectedSetup, ['numBranches', 'subRows', 'branch']),
        });
      }
    });

    props.onSuccess(selectedSetups);
  };

  const onTableParamsChange: ParamsChangeFn = async (filters, pagination, sorting) => {
    const { pageIndex, pageSize } = pagination;

    const queryInput: SetupsQueryInput = {
      filters: mapValues(keyBy(filters, 'id'), 'value'),
    };
    if (sorting.length > 0) queryInput.sorts = { [sorting[0].id]: sorting[0].desc ? 'DESC' : 'ASC' };
    if (!isNil(pageIndex) && !isNil(pageSize)) {
      queryInput.pagination = {
        offset: pageIndex * pageSize,
        limit: pageSize,
      };
    }

    dispatch(tableViewSlice.actions.setSetupSummaryView({ filters, sorting }));
    getSetups({
      variables: { input: queryInput },
      fetchPolicy: 'no-cache',
    });
  };
  const debouncedOnTableParamsChange = debounce(onTableParamsChange, 200);

  return (
    <>
      <Dialog
        className={classNames({ 'bp4-dark': darkMode })}
        isOpen={props.isOpen}
        onClose={props.onClose}
        title="Add Setup(s)"
        style={{ width: '80%', maxWidth: 'none' }}
      >
        <DialogBody>
          <Table
            id="setup-selector"
            columns={columns}
            data={tableData}
            enableRowSelection
            enableSingleRowSelection={props.singleSelection}
            enableHiding
            onRowSelect={onSelectionChange}
            getRowCanExpand={(row) => (row.original.numBranches ? (row.original.numBranches > 1) : false)}
            onExpandedChange={(expandedChanges) => {
              expandedChanges.forEach(ec => {
                if (!ec.rowData.childBranches?.length) return;

                const newTableData = tableData.map(row => {
                  if (row.id === ec.rowData.id) {
                    return {
                      ...row,
                      subRows: row.childBranches?.map(child => GQLBranchChildToSetupTableRow(child)),
                    };
                  }
                  return row;
                });

                setTableData([...newTableData]);
              });
            }}
            getSubRows={(row) => (row.subRows || [])}
            enablePagination
            manualPagination
            manualFiltering
            initialColumnFilters={tableFilters}
            initialSorting={tableSorting}
            persistColumnVisibility
            onParamsChange={debouncedOnTableParamsChange as ParamsChangeFn}
            enableSubRowSelection={false}
            totalRowCount={setupsData?.setupBranchHeads.totalCount || 0}
            highlightedRows={highlightedRowIds}
          />
        </DialogBody>
        <DialogFooter
          actions={[
            <Button key="cancel" onClick={props.onClose} text="Cancel" />,
            <Button
              key="add"
              disabled={isAddDisabled}
              intent={Intent.PRIMARY}
              onClick={onSubmit}
              text={props.singleSelection ? 'Select' : 'Add'}
            />,
          ]}
        />
      </Dialog>
      <Overlay
        isOpen={isLoading && isInitialLoad}
        className="bp3-overlay-scroll-container"
      >
        <div className={styles.loadingSpinner}>
          <Spinner size={50} />
        </div>
      </Overlay>
    </>
  );
};
