import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FormGroup, Intent, Button } from '@blueprintjs/core';
import { SelectItem, SetupFieldInput } from 'types';
import classNames from 'classnames';
import { organizationSelectItems, seriesItems, setupFieldTypeSelectItems, specSelectItems, teamSelectItems, SETUP_FIELD_TYPES } from '../../constants';
import RHFCheckbox from 'components/RHFInputs/Checkbox';
import RHFMultiSelect from 'components/RHFInputs/MultiSelect';
import RHFSelect from 'components/RHFInputs/Select';
import RHFTagInput from 'components/RHFInputs/TagInput';
import RHFTextInput from 'components/RHFInputs/TextInput';
import {
  SetupField,
  SetupFieldType,
  usePartConfigsQuery,
} from 'graphql/generated/graphql';
import { selectDarkMode } from 'reducers/ui';
import Positions from './Positions';
import styles from './index.module.css';
import IconTooltip from 'components/IconTooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouteLoaderData } from 'react-router-dom';
import { find } from 'lodash';
import ExpressionInput from 'components/ExpressionInput';

interface Props {
  isEdit: boolean;
  setupFields: SetupField[]
}

interface ExpanderState {
  [key: string]: boolean;
}

const defaultSetupField: SetupFieldInput = {
  type: SetupFieldType.STRING,
  name: 'childname',
  label: 'Child Label',
  path: 'pathToParent',
  tooltip: 'toottip',
  organization_id: '',
  organization_name: '',
  series: '',
  use_in_sim: false,
  options: [] as string[],
  child_ids: [],
  expression: '',
};

export default (props: Props) => {
  const { control, setValue, getValues, formState: { errors } } = useFormContext();
  const darkMode = useSelector(selectDarkMode);
  const type = useWatch({ control, name: 'type' });
  const collectionPath = useWatch({ control, name: 'path' });
  const useInSim = useWatch({ control, name: 'use_in_sim' });
  const rootName = useWatch({ control, name: 'name' });
  const teamName = useWatch({ control, name: 'team_name' });
  const teamId = useWatch({ control, name: 'team_id' });
  const orgName = useWatch({ control, name: 'organization_name' });
  const partConfigId = useWatch({ control, name: 'part_config_id' });
  const series = useWatch({ control, name: 'series' });
  const specs = useWatch({ control, name: 'specs' });
  const setupFieldArray: SetupFieldInput[] = useWatch({
    control,
    name: 'setup_field_array',
    defaultValue: [],
  });

  const [expandersState, setExpandersState] = useState<ExpanderState>({});

  useEffect(() => {
    setupFieldArray.forEach((field, index) => {
      const fieldToUpdate = {
        ...field,
        child_ids: [],
        use_in_sim: useInSim,
        team_name: teamName,
        team_id: teamId,
        organization_name: orgName,
        series,
        part_config_id: partConfigId,
        specs,
      };
      setValue(`setup_field_array[${index}]`, fieldToUpdate);
    });
  }, [useInSim, teamName, orgName, series, teamId, specs, rootName, partConfigId, setupFieldArray]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { teams, organizations } } = useRouteLoaderData('root') as any;

  // Only fetch part configs when type is PART or Collection (Which may reference a part)
  const { data: partConfigsData } = usePartConfigsQuery({
    skip: type !== SetupFieldType.PART && type !== SetupFieldType.COLLECTION,
  });

  const partTypeItems = (partConfigsData?.partConfigs?.rows || []).map(config => ({
    label: config.display_name,
    value: config.id,
  }));

  // Reset team selections if a user changes organization.
  const findTeam = find(teams, team => team.name === teamName);
  if (findTeam && findTeam.organization.name !== orgName) {
    setValue('team_name', null);
  }

  const toggleExpansion = (index: string) => {
    setExpandersState(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const returnExpressionInput = () => (
    <ExpressionInput
      fields={props.setupFields}
      darkMode={darkMode}
    />
  );

  const returnPartTypeUI = () => {
    const fieldString = 'part_config_id';
    return (
      <FormGroup
        contentClassName={styles.selectFormGroup}
        helperText={<ErrorMessage errors={errors} name={fieldString} />}
        label="Part Type"
        labelInfo="(required)"
      >
        <RHFSelect
          controllerProps={{
            control,
            name: fieldString,
            rules: { required: 'Part Type is required' },
          }}
          intent={errors.part_type && Intent.DANGER}
          items={partTypeItems}
        />
      </FormGroup>
    );
  };

  const returnCollectionFieldsWithChildrenUI = () => {
    return (
      <div>
        { returnSetupFieldCommonUI() }
        <Button
          className={styles.addNewSetupFieldBtn}
          name="add-setup-field"
          text="+ Add Setup Fields"
          color="primary"
          onClick={() => {
            const currentSetupFieldArray = getValues('setup_field_array') || [];
            const newArray = [
              ...currentSetupFieldArray,
              {
                ...defaultSetupField,
                id: props.isEdit ? -1 : undefined,
                organization_name: orgName,
                team_name: teamName,
                team_id: teamId,
                series,
                use_in_sim: specs,
              },
            ];
            setValue('setup_field_array', newArray);
          }}
        />
        {(setupFieldArray.length > 0) && returnCollectionFieldChildren()}
      </div>
    );
  };

  const returnCollectionFieldChildren = () => {
    return (
      <div>
        {setupFieldArray.map((setupFieldInput, index) => (
          <div key={`sf_child_container_${index}`}>
            <span
              className={styles.header}
              onClick={() => toggleExpansion(index.toString())}
            >
              {setupFieldInput.name}
              <div key={`sf_child_container_controls_${index}`}>
                <FontAwesomeIcon
                  fixedWidth
                  icon={expandersState[index] ? 'arrow-from-top' : 'arrow-from-bottom'}
                  size="lg"
                />
                <Button
                  intent={Intent.DANGER}
                  key={`remove_child_setup_field_${index}`}
                  icon="cross"
                  onClick={() => {
                    const currentSetupFieldArray = getValues('setup_field_array') || [];
                    if (currentSetupFieldArray.length > 0 && index >= 0 && index < currentSetupFieldArray.length) {
                      currentSetupFieldArray.splice(index, 1);
                      setValue('setup_field_array', currentSetupFieldArray);
                    }
                  }}
                />
              </div>
            </span>
            <div
              className={classNames(styles.container, {
                [styles.dark]: darkMode,
                [styles.collapsed]: expandersState[index] || false,
              })}
            >
              <FormGroup
                contentClassName={styles.selectFormGroup}
                helperText={<ErrorMessage errors={errors} name={`setup_field_array[${index}].type`} />}
                label="Type"
                labelInfo="(required)"
              >
                <RHFSelect
                  controllerProps={{
                    control,
                    name: `setup_field_array[${index}].type`, // Include index in the name for uniqueness
                    rules: { required: 'Type is required' },
                  }}
                  intent={errors.type && Intent.DANGER}
                  items={setupFieldTypeSelectItems.filter(item => item.label !== SETUP_FIELD_TYPES[SetupFieldType.COLLECTION]) as SelectItem<SetupFieldType>[]}
                />
              </FormGroup>
              {returnCollectionSetupFieldCommonChildrenUI(setupFieldInput, index)}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const returnCollectionSetupFieldCommonChildrenUI = (setupFieldInput: SetupFieldInput, index: number) => {
    const partTypeUI = (setupFieldInput.type === SetupFieldType.PART ? returnPartTypeUI() : (<div />));

    return (
      <div>
        {partTypeUI}
        <FormGroup
          helperText={<ErrorMessage errors={errors} name={`setup_field_array[${index}].name`} />}
          label="Name"
          labelInfo={(
            <>
              <IconTooltip
                content={`
              The name that will be specified in SUITs to include this
              field. It should be lowercase with no spaces, ideally using
              underscores between words.
            `}
              />
              <span> (required)</span>
            </>
          )}
        >
          <RHFTextInput
            controllerProps={{
              control,
              name: `setup_field_array[${index}].name`, // Include index in the name for uniqueness
              rules: {
                required: 'Name is required',
                pattern: {
                  value: /^\S*$/,
                  message: 'Name must not contain spaces',
                },
              },
            }}
            inputProps={{
              intent: errors[`setup_field_array[${index}].name`] && Intent.DANGER,
            }}
          />
        </FormGroup>
        <FormGroup
          helperText={<ErrorMessage errors={errors} name={`setup_field_array[${index}].label`} />}
          label="Label"
          labelInfo="(required)"
        >
          <RHFTextInput
            controllerProps={{
              control,
              name: `setup_field_array[${index}].label`,
              rules: { required: 'Label is required' },
            }}
            inputProps={{
              intent: errors[`setup_field_array[${index}].label`] && Intent.DANGER,
            }}
          />
        </FormGroup>
        <FormGroup
          helperText={<ErrorMessage errors={errors} name={`setup_field_array[${index}].path`} />}
          label="Path"
          labelInfo={(
            <>
              <IconTooltip
                content={`Path Relative to Collection path: '${collectionPath}'.
              When this field is used in simulations, this path should reflect
              the value's location in the simulation plan. Otherwise, this
              path specifies where the value resides in the setup document.
            `}
              />
              <span> (required)</span>
            </>
          )}
        >
          <RHFTextInput
            controllerProps={{
              control,
              name: `setup_field_array[${index}].path`,
              rules: {
                required: 'Path is required',
                pattern: {
                  value: /^\S*$/,
                  message: 'Path must not contain spaces',
                },
              },
            }}
            inputProps={{
              intent: errors[`setup_field_array[${index}].path`] && Intent.DANGER,
            }}
          />
        </FormGroup>
        <FormGroup
          helperText={<ErrorMessage errors={errors} name={`setup_field_array[${index}].tooltip`} />}
          label="Tooltip"
          labelInfo={(
            <IconTooltip
              content="Text shown in a tooltip when hovering this field"
            />
          )}
        >
          <RHFTextInput
            controllerProps={{
              control,
              name: `setup_field_array[${index}].tooltip`, // Include index in the name for uniqueness
            }}
            inputProps={{
              intent: errors[`setup_field_array[${index}].tooltip`] && Intent.DANGER,
            }}
          />
        </FormGroup>
        {(setupFieldInput.type === SetupFieldType.STRING || setupFieldInput.type === SetupFieldType.INT) && (
          <FormGroup
            label="Options"
            labelInfo={(
              <IconTooltip
                content={`
              Optional. When specified, options limit the values an input can
              have by rendering a dropdown with the specified values rather
              than a text input.
            `}
              />
            )}
          >
            <RHFTagInput
              controllerProps={{
                control,
                name: Array.isArray(setupFieldInput.options)
                  ? `setup_field_array[${index}].options.join(', ')` // Adjusted for uniqueness
                  : '',
              }}
            />
          </FormGroup>
        )}
      </div>
    );
  };

  const returnSetupFieldCommonUI = () => {
    const partTypeUI = (type === SetupFieldType.PART ? returnPartTypeUI() : (<div />));
    const showPositions = type !== SetupFieldType.COLLECTION && type !== SetupFieldType.EXPRESSION;

    return (
      <div>
        {partTypeUI}
        <FormGroup
          helperText={<ErrorMessage errors={errors} name="organization_name" />}
          intent={errors.organization_name ? Intent.DANGER : Intent.NONE}
          label="Organization"
          labelInfo="(required)"
        >
          <RHFSelect
            controllerProps={{
              control,
              name: 'organization_name',
              rules: {
                required: 'Organization is required',
              },
            }}
            intent={errors.organization_name && Intent.DANGER}
            items={organizationSelectItems(organizations)}
          />
        </FormGroup>
        <FormGroup
          helperText={<ErrorMessage errors={errors} name="team_name" />}
          intent={errors.team_name ? Intent.DANGER : Intent.NONE}
          label="Team"
        >
          <RHFSelect
            controllerProps={{
              control,
              name: 'team_name',
            }}
            disabled={!orgName}
            intent={errors.team_name && Intent.DANGER}
            items={teamSelectItems(teams, orgName)}
          />
        </FormGroup>
        <FormGroup
          helperText={<ErrorMessage errors={errors} name="series" />}
          intent={errors.series ? Intent.DANGER : Intent.NONE}
          label="Series"
          labelInfo="(required)"
        >
          <RHFSelect
            controllerProps={{
              control,
              name: 'series',
              rules: {
                required: 'Series is required',
              },
            }}
            intent={errors.series && Intent.DANGER}
            items={seriesItems}
          />
        </FormGroup>
        <FormGroup
          helperText={<ErrorMessage errors={errors} name="specs" />}
          label="Specs"
          labelInfo="(leave empty for all specs)"
        >
          <RHFMultiSelect
            controllerProps={{
              control,
              name: 'specs',
            }}
            items={specSelectItems}
            selectProps={{ fill: true }}
          />
        </FormGroup>
        <FormGroup
          helperText={<ErrorMessage errors={errors} name="name" />}
          label="Name"
          labelInfo={(
            <>
              <IconTooltip
                content={`
                The name that will be specified in SUITs to include this
                field. It should be lowercase with no spaces, ideally using
                underscores between words.
              `}
              />
              <span> (required)</span>
            </>
          )}
        >
          <RHFTextInput
            controllerProps={{
              control,
              name: 'name',
              rules: {
                required: 'Name is required',
                pattern: {
                  value: /^\S*$/,
                  message: 'Name must not contain spaces',
                },
              },
            }}
            inputProps={{
              intent: errors.name && Intent.DANGER,
            }}
          />
        </FormGroup>
        <FormGroup
          helperText={<ErrorMessage errors={errors} name="label" />}
          label="Label"
          labelInfo="(required)"
        >
          <RHFTextInput
            controllerProps={{
              control,
              name: 'label',
              rules: { required: 'Label is required' },
            }}
            inputProps={{
              intent: errors.label && Intent.DANGER,
            }}
          />
        </FormGroup>
        <FormGroup
          helperText={<ErrorMessage errors={errors} name="path" />}
          label="Path"
          labelInfo={(
            <>
              <IconTooltip
                content={`
                When this field is used in simulations, this path should reflect
                the value's location in the simulation plan. Otherwise, this
                path specifies where the value resides in the setup document.
              `}
              />
              <span> (required)</span>
            </>
          )}
        >
          <RHFTextInput
            controllerProps={{
              control,
              name: 'path',
              rules: {
                required: 'Path is required',
                pattern: {
                  value: /^\S*$/,
                  message: 'Path must not contain spaces',
                },
              },
            }}
            inputProps={{
              intent: errors.path && Intent.DANGER,
            }}
          />
        </FormGroup>
        <FormGroup
          helperText={<ErrorMessage errors={errors} name="tooltip" />}
          label="Tooltip"
          labelInfo={(
            <IconTooltip
              content="Text shown in a tooltip when hovering this field"
            />
          )}
        >
          <RHFTextInput
            controllerProps={{
              control,
              name: 'tooltip',
            }}
            inputProps={{
              intent: errors.tooltip && Intent.DANGER,
            }}
          />
        </FormGroup>
        {type === SetupFieldType.EXPRESSION && returnExpressionInput()}
        {showPositions && (
          <FormGroup
            label="Positions"
            labelInfo={(
              <IconTooltip
                content={(
                  <div className={classNames(styles.tooltipContent, { [styles.dark]: darkMode })}>
                    <p>
                      Optional. <strong>Each position represents a separate input</strong> for e.g. LF,
                      Front, Upper Left, etc. By default, if a path part is not provided,
                      the label will be appended to the path above. If path part is
                      provided, it will replace the final section of the path above.
                    </p>
                    <p>
                      For example, with a path of <code>vehicle.aerodynamics.downforce_offset</code> and
                      a position with path part <code>fdf_offset</code>, the position's
                      input's path will be <code>vehicle.aerodynamics.fdf_offset</code>.
                    </p>
                    <p>
                      When a path part is not provided, for example with a path
                      of <code>vehicle.chassis.front_clip_shim</code> and a label of
                      "Upper Left", the position's input's path will
                      be <code>vehicle.chassis.front_clip_shim_upper_left</code>.
                    </p>
                  </div>
                )}
              />
            )}
          >
            <Positions />
          </FormGroup>
        )}
        {(type === SetupFieldType.STRING || type === SetupFieldType.INT) && (
          <FormGroup
            label="Options"
            labelInfo={(
              <IconTooltip
                content={`
                Optional. When specified, options limit the values an input can
                have by rendering a dropdown with the specified values rather
                than a text input.
              `}
              />
            )}
          >
            <RHFTagInput
              controllerProps={{
                control,
                name: 'options',
              }}
            />
          </FormGroup>
        )}
        <FormGroup label="Used in sim" inline>
          <RHFCheckbox
            controllerProps={{
              name: 'use_in_sim',
            }}
            checkboxProps={{
              checked: useInSim,
            }}
          />
        </FormGroup>
      </div>
    );
  };

  return (
    <>
      <FormGroup
        contentClassName={styles.selectFormGroup}
        helperText={<ErrorMessage errors={errors} name="type" />}
        label="Type"
        labelInfo="(required)"
      >
        <RHFSelect
          controllerProps={{
            control,
            name: 'type',
            rules: { required: 'Type is required' },
          }}
          intent={errors.type && Intent.DANGER}
          items={setupFieldTypeSelectItems}
        />
      </FormGroup>
      {type === SetupFieldType.COLLECTION ? (
        returnCollectionFieldsWithChildrenUI()
      ) : (
        returnSetupFieldCommonUI()
      )}
    </>
  );
};
